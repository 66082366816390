<template>
  <div>
    <div class="result-panel" style="margin-top: 0">
      <CSTable>
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <router-link :to="{name: 'addFlightMission'}">
              <button class="btn btn-primary sticky-right">
                <svg aria-hidden="true" class="icon">
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                航点飞行任务
              </button>
            </router-link>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>添加时间</th>
            <th>航点飞行任务</th>
            <th>航点</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="mission in missionList" :key="mission.id">
            <td class="date-td">{{ mission.createTime }}</td>
            <td>{{ mission.name || '-' }}</td>
            <td>
              <template v-if="mission.wayPoints && mission.wayPoints.length > 0">
                <span class="allow-click" @click="lookWayPoints(mission)">查看</span>
              </template>
              <template v-else>-</template>
            </td>
            <td>
              <div class="btn-group">
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>
                <ul class="dropdown-menu" style="width: 76px">
                  <li>
                    <router-link :to="{
                                            name: 'editFlightMission',
                                            params: {
                                                id: mission.id,
                                                flight: mission.flightHeight || 0,
                                                back: mission.courseReversalHeight || 0,
                                            },
                                            query: {
                                                name: mission.name,
                                                points: mission.wayPoints || ''
                                            }
                                        }">
                      修改任务
                    </router-link>
                  </li>
                  <li @click="deleteFlightMission(mission.id)">
                    <a>删除任务</a>
                  </li>
                  <li @click="openUploadImg(mission.id)">
                    <a>上传图片</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <pagination/>
      <CSDialog :dialog-show-confirm-btn="false"
                :dialog-visible="missionInfoDialog.visible"
                dialog-cancel-btn-text="关闭"
                dialog-title="查看航点详情"
                dialog-width="920px"
                dialogHeaderClass="alert-bg"
                @onClose="missionInfoDialog.visible = false"
      >
        <template v-slot:dialog-content>
          <div style="width: 920px;padding: 30px;">
            <DroneMap :back="missionInfoDialog.data.courseReversalHeight" :default-name="missionInfoDialog.data.name" :default-way-points="missionInfoDialog.data.wayPoints"
                      :flight="missionInfoDialog.data.flightHeight"
                      isPreview style="height: 640px;"
            />
          </div>
        </template>
      </CSDialog>

      <!-- 上传图片 -->
      <CSDialog
          :dialog-visible="uploadImgDialog.visible"
          dialog-title="上传图片"
          dialog-width="430px"
          @onClose="uploadImgDialog.visible = false"
          @onConfirm="setFlightMissionPointImg"
      >
        <template v-slot:dialog-content>
          <div class="upload-staff-dialog-content">
            <div>
              <div
                  v-if="uploadImgDialog.fileUrl === ''"
                  :class="['upload-photo']"
                  @click="triggerChoosePhoto"
              >
                <div class="text-center">
                  <img
                      alt=""
                      src="@/assets/upload.png"
                      style="margin: 5px auto 5px"
                  />
                  <p style="color: #999">上传图片</p>
                </div>
              </div>
              <div
                  v-else
                  :class="['photo-view']"
                  @click="triggerChoosePhoto"
              >
                <img :src="uploadImgDialog.fileUrl" alt=""/>
              </div>
              <input
                  id="uploadPhoto"
                  accept="images/*"
                  class="file"
                  hidden
                  type="file"
                  @change="choosePhoto($event)"
              />
            </div>
            <div class="tips">
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menua-zu92"></use>
              </svg>
              图片大小为10:7。
            </div>
          </div>
        </template>
      </CSDialog>
    </div>
  </div>
</template>

<script>
import CSTable from "@/components/common/CSTable";
import {
  commonImgOssDomain,
  deleteFlightMissionUrl,
  queryFlightMissionUrl,
  setFlightPointImgUrl,
  uploadPhotoUrl
} from "@/requestUrl";
import Pagination from "@/components/Pagination";
import CSDialog from "@/components/common/CSDialog";
import DroneMap from "@/views/Drones/DroneMap";

export default {
  name: "FlightMission",
  components: {DroneMap, CSDialog, Pagination, CSTable},
  data() {
    return {
      missionList: [],
      missionInfoDialog: {
        visible: false,
      },
      uploadImgDialog: {
        visible: false,
        fileUrl: "",
      },
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  mounted() {
    this.getFlightMission();
    this.$vc.on(this.$route.path, 'pagination_page', 'event', (page) => {
      this.pageParams.pageNo = page;
      this.getFlightMission(page);
    })
  },
  methods: {
    setFlightMissionPointImg() {
      this.$fly.post(setFlightPointImgUrl, {
        id: this.uploadImgDialog.id,
        picture: this.uploadImgDialog.fileUrl
      })
          .then(res => {
            if (res.code === 0) {
              this.uploadImgDialog.visible = false;
            }
          })
    },
    triggerChoosePhoto() {
      document.getElementById("uploadPhoto").click();
    },
    // 选择文件后处理
    async choosePhoto(event) {
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        // 获取目前上传的文件
        var file = photoFiles[0]; // 文件大小校验的动作
        this.uploadPhoto(file);
        /*if (file.size > 1024 * 100 * 2) {
          this.$vc.toast("图片大小不能超过 200kb!");
          return false;
        }*/
      }
    },
    fileByBase64(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          resolve(e.target.result);
        };
      });
    },
    // 上传图片
    uploadPhoto(file) {
      let formData = new FormData();
      formData.append("file", file);
      this.$fly
          .post(uploadPhotoUrl, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async (res) => {
            if (res.code !== 0) {
              return;
            }

            this.uploadImgDialog.fileUrl = commonImgOssDomain + res.data.ossUrl;
          });
    },
    /**
     * 上传图片
     * @param {Number} id
     * */
    openUploadImg(id) {
      this.uploadImgDialog = {
        visible: true,
        id,
        fileUrl: '',
      };
    },
    lookWayPoints(missionInfo) {
      this.missionInfoDialog.visible = true;
      this.missionInfoDialog.data = {};
      for (let key in missionInfo) {
        if (key === 'wayPoints') {
          this.missionInfoDialog.data.wayPoints = JSON.parse(missionInfo.wayPoints || '[]').map(point => point.split(','));
          continue;
        }
        this.missionInfoDialog.data[key] = missionInfo[key]
      }
    },
    // 获取飞行任务
    getFlightMission(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly.post(queryFlightMissionUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        pageNo,
        pageSize
      })
          .then(res => {
            this.missionList = res.data.datas ?? [];
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                pageSize,
                currentPage: pageNo
              })
            }
          })
    },
    /**
     * 删除飞行任务
     * @param {Number} id 任务id
     * */
    deleteFlightMission(id) {
      this.$CSDialog.confirm({
        title: '提示',
        message: '确定删除飞行任务吗？',
        onConfirm: () => {
          this.$fly.get(deleteFlightMissionUrl, {
            id
          })
              .then(res => {
                if (res?.code === 0) {
                  this.$vc.toast('删除成功');
                  this.$CSDialog.instance.closeDialog();
                  this.getFlightMission();
                }
              })
        }
      })
    },
  }
}
</script>

<style lang="stylus" scoped>
.dropdown-menu
  li
    a
      width 100%

.upload-staff-dialog-content
  padding 20px

  .tips
    margin-top 20px
    line-height 1
    color #999
    font-size 20px

/*    .upload-photo
        position: relative;
        &.verifyPhoto
            &::before
                @extend .verifyPhoto-bg;
    .photo-view
        &.verifyPhoto
            &::before
                @extend .verifyPhoto-bg*/


</style>


